import { APPLICATION_STEP } from "~/constants/steps/steps";
import type { ValidationSubject } from "~/types/database";

const VALIDATION_SUBJECT = {
  THIRD_PARTY_VALIDATION: "third_party_validation",
  THIRD_PARTY_CREATION: "third_party_creation",
  THIRD_PARTY_MODIFICATION: "third_party_modification",
  ORGANISATION_ADMISSIBILITY: "organisation_admissibility",
  LIQUIDATION_ADMISSIBILITY: "liquidation_admissibility",
  LIQUIDATION_INDICATAGE: "liquidation_indicatage",
  LIQUIDATION_VALIDATION: "liquidation_validation",
  ADMISSIBILITY: "admissibility",
  DECISION: "decision",
  RESUFAL_VALIDATION: "refusal",
  BUDGET: "budget",
  SUBVENTION_PROJECT_VALIDATION: "subvention_project_validation",
  SUBVENTION_PROJECT_OPINION: "subvention_project_opinion",
  SIGNATURE_GRANT_DECREE: "signature_grant",
  SIGNATURE_NEWS_LETTER: "signature_news_letter",
  CONTROL_COMPLETENESS: "control_completeness",
  CONTROL_VALIDATION: "control_validation",
  CONTROL_REQUEST: "control_request",
  REVIEW_FORM_ANSWERS_VALIDATION: "review_form_answers_validation",
  REVIEW_FORM_ANSWERS_MODIFICATION: "review_form_answers_modification",
} as const;

const VALIDATION_SUBJECT_FRENCH: { [k in ValidationSubject]: string } = {
  [VALIDATION_SUBJECT.THIRD_PARTY_VALIDATION]: "la validation du tiers",
  [VALIDATION_SUBJECT.THIRD_PARTY_CREATION]: "la demande de création du tiers",
  [VALIDATION_SUBJECT.THIRD_PARTY_MODIFICATION]: "la demande de modification du tiers",
  [VALIDATION_SUBJECT.ADMISSIBILITY]: "la soumission du dossier",
  [VALIDATION_SUBJECT.DECISION]: "le dossier",
  [VALIDATION_SUBJECT.RESUFAL_VALIDATION]: "la lettre de mauvaise nouvelle",
  [VALIDATION_SUBJECT.BUDGET]: "la proposition budgétaire",
  [VALIDATION_SUBJECT.SUBVENTION_PROJECT_VALIDATION]: "le projet subvention",
  [VALIDATION_SUBJECT.SUBVENTION_PROJECT_OPINION]: "le projet de subvention",
  [VALIDATION_SUBJECT.SIGNATURE_GRANT_DECREE]: "l'arrêté de subvention",
  [VALIDATION_SUBJECT.SIGNATURE_NEWS_LETTER]: "la lettre de bonne nouvelle",
  [VALIDATION_SUBJECT.LIQUIDATION_ADMISSIBILITY]: "la liquidation",
  [VALIDATION_SUBJECT.LIQUIDATION_INDICATAGE]: "la liquidation",
  [VALIDATION_SUBJECT.LIQUIDATION_VALIDATION]: "la liquidation",
  [VALIDATION_SUBJECT.CONTROL_COMPLETENESS]: "la complétude des pièces justificatives",
  [VALIDATION_SUBJECT.CONTROL_VALIDATION]: "les pièces justificatives",
  [VALIDATION_SUBJECT.CONTROL_REQUEST]: "la demande de contrôle des liquidations",
  [VALIDATION_SUBJECT.ORGANISATION_ADMISSIBILITY]: "l'organisme",
  [VALIDATION_SUBJECT.REVIEW_FORM_ANSWERS_MODIFICATION]: "la demande de modification du formulaire de bilan de subvention",
  [VALIDATION_SUBJECT.REVIEW_FORM_ANSWERS_VALIDATION]: "la demande de complétude du formulaire de bilan de subvention",
};

const VALIDATION = {
  VALIDE: "valide",
  REFUSE: "refuse",
  MODIFICATION_REQUEST: "modification_requests",
} as const;

const APPLICATION_CLOSURE_VALIDATION = {
  [APPLICATION_STEP.CONTROL_OVERPAYMENT]: APPLICATION_STEP.CONTROL_OVERPAYMENT,
  [APPLICATION_STEP.CONTROL]: APPLICATION_STEP.CONTROL,
  [APPLICATION_STEP.ARCHIVED]: APPLICATION_STEP.ARCHIVED,
} as const;

export { VALIDATION, VALIDATION_SUBJECT_FRENCH, VALIDATION_SUBJECT, APPLICATION_CLOSURE_VALIDATION };
